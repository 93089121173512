import React from 'react'

const Privacy = () => {
  return (
    <div className="mt-20 ml-32 mr-32">
        <p>PRIVACY NOTICE</p>
    <p>Last updated February 17, 2020</p>
    
    
     <p>
     Thank you for choosing to be part of our community at HoloView Technologies (“Company”, “we”, “us”, or “our”). We are committed to protecting your personal information and your right to privacy. If you have any questions or concerns about our notice, or our practices with regards to your personal information, please contact us at trong@igitechnologies.com.
    </p>
    <p>
    When you visit our mobile application, and use our services, you trust us with your personal information. We take your privacy very seriously. In this privacy notice, we seek to explain to you in the clearest way possible what information we collect, how we use it and what rights you have in relation to it. We hope you take some time to read through it carefully, as it is important. If there are any terms in this privacy notice that you do not agree with, please discontinue use of our Apps and our services.

    </p>
    <p>
    This privacy notice applies to all information collected through our mobile application, ("Apps"), and/or any related services, sales, marketing or events (we refer to them collectively in this privacy notice as the "Services").
    
    Please read this privacy notice carefully as it will help you make informed decisions about sharing your personal information with us.
    
     </p>
    
    
     
    
    <h2>1. WHAT INFORMATION DO WE COLLECT?</h2>
    
    
    Information collected through our Apps
    
    We do not collect any information when you use our apps.
    
     
    
    <h2>2. WHAT ARE YOUR PRIVACY RIGHTS?</h2>
    
    In Short:  You may review, change, or terminate your account at any time.
    
     
    
    If you are resident in the European Economic Area and you believe we are unlawfully processing your personal information, you also have the right to complain to your local data protection supervisory authority. You can find their contact details here: http://ec.europa.eu/justice/data-protection/bodies/authorities/index_en.htm.
    
     
    
     
    <h2>3. CONTROLS FOR DO-NOT-TRACK FEATURES</h2>
    
    
    Most web browsers and some mobile operating systems and mobile applications include a Do-Not-Track (“DNT”) feature or setting you can activate to signal your privacy preference not to have data about your online browsing activities monitored and collected. No uniform technology standard for recognizing and implementing DNT signals has been finalized. As such, we do not currently respond to DNT browser signals or any other mechanism that automatically communicates your choice not to be tracked online. If a standard for online tracking is adopted that we must follow in the future, we will inform you about that practice in a revised version of this privacy notice.
    
     
   <h2>4. DO CALIFORNIA RESIDENTS HAVE SPECIFIC PRIVACY RIGHTS?</h2> 
    
    
    In Short:  Yes, if you are a resident of California, you are granted specific rights regarding access to your personal information.
    
    California Civil Code Section 1798.83, also known as the “Shine The Light” law, permits our users who are California residents to request and obtain from us, once a year and free of charge, information about categories of personal information (if any) we disclosed to third parties for direct marketing purposes and the names and addresses of all third parties with which we shared personal information in the immediately preceding calendar year. If you are a California resident and would like to make such a request, please submit your request in writing to us using the contact information provided below.
    
    If you are under 18 years of age, reside in California, and have a registered account with the Apps, you have the right to request removal of unwanted data that you publicly post on the Apps. To request removal of such data, please contact us using the contact information provided below, and include the email address associated with your account and a statement that you reside in California. We will make sure the data is not publicly displayed on the Apps, but please be aware that the data may not be completely or comprehensively removed from our systems.
    
     
    <h2>5. DO WE MAKE UPDATES TO THIS POLICY?</h2>
    
    
    In Short:  Yes, we will update this policy as necessary to stay compliant with relevant laws.
    
    We may update this privacy notice from time to time. The updated version will be indicated by an updated “Revised” date and the updated version will be effective as soon as it is accessible. If we make material changes to this privacy notice, we may notify you either by prominently posting a notice of such changes or by directly sending you a notification. We encourage you to review this privacy notice frequently to be informed of how we are protecting your information.
    
     
    <h2>6. HOW CAN YOU CONTACT US ABOUT THIS POLICY?</h2>
    
    
    If you have questions or comments about this policy, you may email us at holoview.app@gmail.com or by post to:
    
    HoloView Technologies 
    
     
    
    4059 CANDLE LIGHT DR
    
    Dayton, MD 21036
    
    United States</div>
  )
}

export default Privacy